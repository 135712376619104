import { IAddress } from "../office-profiles/office-profiles.types";

export class VacancyApplicantNoteFormDto {
  id = 0;
  note = "";
}

export class VacancyApplicantNoteDto {
  id = 0;
  note = "";
  userId = 0;
  creatorId = 0;
  creatorName = "";
  dateCreated = new Date();
}

export class VacancyApplicantDto {
  userId = 0;
  vacancyId = 0;
  name = "";
  isFavorite = false;
  email = "";
  phoneNumber = "";
  isInterested?: boolean;
  dateApplied = "";
  isBlocked = false;
  isHired = false;
  profileImgUrl = "";
  notes: VacancyApplicantNoteDto[] = [];
}

export class VacancyDetailsDto implements IAddress {
  id = 0;
  position = "";
  schedules: string[] = [];
  jobType = "";
  supplementalPays: string[] = [];
  signingBonus?: number;
  benefits: string[] = [];
  estimatedSalary?: number;
  estimatedSalaryMax?: number;
  estimatedSalaryMin?: number;
  estimatedSalaryHourly?: number;
  description = "";
  applicants: VacancyApplicantDto[] = [];
  address1 = "";
  address2: string | null = null;
  city = "";
  state = "";
  zipCode = "";
}

export interface IVacancyDto extends IAddress {
  id: number;
  position: string;
  officeName: string;
  estimatedSalary: number;
  estimatedSalaryMax: number;
  estimatedSalaryMin: number;
  estimatedSalaryHourly: number;
  jobType: string;
  applicantCount: number;
  officeSchedulerIds: number[];
  blockCount: number;
  status: VacancyStatus;
}

export class VacancyFormDto {
  id = 0;
  position: number | null = null;
  schedules: number[] = [];
  jobType: number | null = null;
  supplementalPays: number[] = [];
  signingBonus: number | null = null;
  benefits: number[] = [];
  estimatedSalary: number | null = null;
  estimatedSalaryMax: number | null = null;
  estimatedSalaryMin: number | null = null;
  estimatedSalaryHourly: number | null = null;
  description: string | null = null;
  practiceOfficeId: number | null = null;
  isAnonymous = false;
  schedulerIds: number[] | null = [];
}

export class VacancyFormDataDto {
  positions: ISelectOption[] = [];
  schedules: ISelectOption[] = [];
  jobTypes: ISelectOption[] = [];
  supplementalPays: ISelectOption[] = [];
  benefits: ISelectOption[] = [];
  estimatedSalaries: ISelectOption[] = [];
  schedulers: ISelectOption[] = [];
}

export interface ISelectOption {
  value: number;
  text: string;
  identifier: string;
}

export enum VacancyStatus {
  Active = 1,
  Deleted,
  Filled
}