import httpAuth from "@/common/http/http-common";
import { 
  UpdateJobPostRatingDto
} from "./ratings.types";

class RatingsService {
  async getPracticeRatingsOfDt(practiceId: number) {
    const { data } = await httpAuth().get(
      `rating/practice/${practiceId}/dental-temp/all`
    );

    return data;
  }

  async updateJobPostRating(model: UpdateJobPostRatingDto) {
    const { data } = await httpAuth().put(`rating`, model);

    return data;
  }
}

export default new RatingsService();