
import { Component, Mixins } from 'vue-property-decorator';
import "vue2-editor/dist/vue2-editor.css";
import { VueEditor } from "vue2-editor";
import AuthenticateMixin from '../authenticate/authenticate.mixin';
import { VacancyApplicantDto, VacancyApplicantNoteDto, VacancyApplicantNoteFormDto } from './vacancies-types';
import { mapState } from 'vuex';
import ValidatedFormMixin from '@/common/form/validated-form.mixin';
import { format as formatDate } from '@/utils/dateUtils';

@Component({
  components: {
    VueEditor
  },
  computed: {
    ...mapState("Vacancy", { selectedApplicant: "vacancyApplicant", form: "vacancyNoteForm" })
  }
})
export default class VacancyCreateEditNoteComponent extends Mixins(AuthenticateMixin, ValidatedFormMixin) {
  selectedApplicant!: VacancyApplicantDto;
  form!: VacancyApplicantNoteFormDto;

  selectedDeleteId = 0;

  loading = false;
  formatDate = formatDate;

  editorToolbar = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    ["clean"]
  ];

  get initials(): string {
    if (this.selectedApplicant){
      const splitName = this.selectedApplicant.name.split(" ");

      return splitName.map(n => n[0]).join("");
    }

    return "";
  }

  async deleteNote(): Promise<void> {
    await this.$store.dispatch("Vacancy/deleteNote", this.selectedDeleteId);
    this.$emit("delete", this.selectedApplicant.userId);
    this.resetForm();
  }

  showDeleteModal(id: number): void {
    this.selectedDeleteId = id;
    this.$bvModal.show("note-delete-modal");
  }

  onEdit(data: VacancyApplicantNoteDto): void {
    const form = {
      id: data.id,
      note: data.note
    } as VacancyApplicantNoteFormDto

    this.$store.commit('Vacancy/updateField', { path: 'vacancyNoteForm', value: form });
  }

  cancelEdit(): void {
    this.resetForm();
  }

  async save(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        try {
          await this.$store.dispatch("Vacancy/saveNote", { form: this.form, userId: this.selectedApplicant.userId });
        }
        catch {
          return;
        }
        this.loading = false;
        this.$emit("create", this.selectedApplicant.userId);
        this.resetForm();
      }
    });
  }

  resetForm(): void {
    this.$store.commit('Vacancy/updateField', { path: 'vacancyNoteForm', value: new VacancyApplicantNoteFormDto() });
  }

  closeModal(): void {
    this.resetForm();
    this.$bvModal.hide('vacancy-create-edit-note-modal');
  }

  onCloseModal(): void {
    if (this.form.id) {
      if (this.form.note !== this.selectedApplicant.notes.find(note => note.id === this.form.id)?.note) {
        return this.$bvModal.show('note-close-modal');
      }
    }
    else {
      if (this.form.note) {
        return this.$bvModal.show('note-close-modal');
      }
    }

    this.closeModal();
  }
}
