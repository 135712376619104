export class RatingItemsModel {
  id: number = 0;
  member: string = "";
  office: string = "";
  date: string = "";
  rating: number = 0;
  comments: string | null = "";
  userId: number = 0;
  noShow: boolean = false;
  sickCall: boolean = false;

  disabled: boolean = true;
  log: string = "";
}

export class RatingsModel {
  jobPostRatingByPracticeOfficeId: number = 0;
  dtLastName: string = "";
  dtFirstName: string = "";
  startDateOnly: string = "";
  officeName: string = "";
  rating: number = 0;
  comments: string = "";
  status: number = 0;
  raterLastName: string = "";
  raterFirstName: string = "";
  lastModifiedDate: string = "";
  userId: number = 0;
  noShow: boolean = false;
  sickCall: boolean = false;
}

export class UpdateJobPostRatingDto {
  jobPostRatingByPracticeOfficeId: number = 0;
  rating: number = 0;
  comments: string | null = null;
  status: number = 0;
  ratedByPracticeOfficeUserId: number = 0;
  noShow: boolean = false;
}

export enum RatingsStatus {
  Open = 92,
  Active = 93,
  Dismissed = 95
}