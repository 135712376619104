import httpAuth from "@/common/http/http-common";


class EmployeeDetailsService {
  async getPracticeRatingsOfDt(practiceId: number, userId: number, practiceOfficeId?: number) {
    let url = `rating/practice/${practiceId}/user/${userId}/details`;

    if (practiceOfficeId) {
      url += `?practiceOfficeId=${practiceOfficeId}`;
    }

    const { data } = await httpAuth().get(url);
    
    return data;
  }

  async getCertificateImage(certificateId: number) {
    const url = `employee/certificate/${certificateId}/download`;
    const { data } = await httpAuth().get(url, {
      responseType: 'blob',
      timeout: 30000,
    });
    return data;
  }

  async getBlockStatus(practiceId: number, userId: number): Promise<boolean> {
    const { data } = await httpAuth().get(
      `employee/practice/${practiceId}/user/${userId}/block`
    );
    return data;
  }

  async toggleBlockStatus(practiceId: number, userId: number): Promise<void> {
    const { data } = await httpAuth().put(
      `employee/practice/${practiceId}/user/${userId}/block`
    );
    return data;
  }
}

export default new EmployeeDetailsService();