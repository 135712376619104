export class PracticeUser {
  userId: number | null = null;
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  phoneNumber: string = "";
  emailAddress: string = "";
  practiceOfficeIds: number[] = [];
}

export class PracticeOfficeDropdown {
  text: string = "";
  value: number = 0;
}

export class PracticeOfficeSelect {
  practiceOfficeId: number = 0;
  status: string = "";
}

export class UpsertSchedulerDto {
  userId: number | null = null;
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  phoneNumber: string = "";
  email: string = "";
  practiceOfficeSelect: PracticeOfficeSelect[] = [];
}