
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import PostingsMixin from "./postings.mixin";
import CreateEditShift from "./modals/create-edit-shift.vue";
import { PracticeOfficeModel, ShiftFormDto } from "./postings.types";
import { mapState } from "vuex";
import PostingGrid from "./posting-grid.vue";
import PostingTable from "./posting-table.vue";

const basePath = require('@/assets/notification-icons.svg')

interface JobBoardSettings {
  isGridView: boolean;
}

@Component({
  components: {
    CreateEditShift,
    PostingGrid,
    PostingTable
  },
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice",
      "practiceOffices"
    ])
  }
})
export default class PostingsComponent extends Mixins(PostingsMixin, AuthenticateMixin) {
  selectedOffice!: PracticeOfficeModel;
  practiceOffices!: PracticeOfficeModel[];
  clearFilterIconPath = basePath + "#icon-clear-filter";

  showCurrentUserJobPosts = false;
  modalShow = false;
  isGridView = true;
  loading = false;

  async created(): Promise<void> {
    this.loading = true;

    const jobBoardSettingsRaw = sessionStorage.getItem("job-board-settings");

    if (jobBoardSettingsRaw) {
      const jobBoardSettings = JSON.parse(jobBoardSettingsRaw) as JobBoardSettings;

      this.isGridView = jobBoardSettings.isGridView;
    }

    await this.$store.dispatch("Postings/fetchPracticeOffices", this.userInfo()?.sub);

    this.loading = false;
  }

  async fetchData(): Promise<void> {
    if (this.isGridView) {
      await this.fetchJobPostings(this.selectedOffice.practiceOfficeId);
    }
    else {
      await this.fetchJobPostings();
    }
  }

  setLoading(value: boolean): void {
    this.loading = value;
  }

  toggleGridView(value: boolean): void {
    this.isGridView = value;
    sessionStorage.setItem("job-board-settings", JSON.stringify({ isGridView: this.isGridView }));
  }

  async onOfficeSelect(value: PracticeOfficeModel): Promise<void> {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
    await this.fetchJobPostings(this.selectedOffice.practiceOfficeId);
  }

  async onEdit(shiftGroupId: number): Promise<void> {
    await this.$store.dispatch("Postings/getShiftForm", shiftGroupId);

    this.modalShow = true;
  }

  showPostShift(): void {
     this.$store.commit("Postings/updateField", {
      path: "shiftForm",
      value: new ShiftFormDto()
    });
    
    this.modalShow = true;
  }

  destroyed(): void {
    clearInterval(this.timerRef);
  }
}
