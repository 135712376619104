
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { UserFormDto } from "@/common/types";
import { mapState } from "vuex";

interface IImageRef extends Element {
  $el: {
    childNodes: HTMLElement[]
  }
}

@Component({
  computed: {
    ...mapState('User', {
      form: 'userForm'
    })
  }
})
export default class UserEditModalComponent extends Vue {
  @Prop() modalShow!: boolean;
  @Prop() id!: number;

  form!: UserFormDto;
  profileFile: File | null = null;

  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  get initials(): string {
    return this.form.firstName[0] + this.form.lastName[0];
  }

  async mounted(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("User/getForm", this.id);
    this.loading = false;
  }

  validateState(ref: string): boolean | null {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  onUploadClick(): void {
    (this.$refs.image as IImageRef).$el.childNodes[0].click()
  }

  base64Encode(data: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result as string);
    });
  }

  @Watch('profileFile')
  onProfileFileChanged(newValue: File): void {
    this.base64Encode(newValue)
      .then(value => {
        this.form.profileUrl = null;
        this.form.profileData = value;
      }
    );
  }

  async update(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        try {
          await this.$store.dispatch("User/update", this.form);
          this.form.profileData = null;
          this.form.profileUrl = null;
        }
        catch {
          this.loading = false;
          return;
        }
        await this.$store.dispatch("Authenticate/storeUserInfo");
        this.$emit("success");
        this.loading = false;
        this.$emit('update:modalShow', false);
      }
    });
  }

  closeModal(): void {
    this.$emit('update:modalShow', false);
  }
}
