
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import { AcceptedJobPost, EmployeeDetailsModel, IOpenJobPost, IRequestedJobPost, JobPost, JobPostStatus, PracticeOfficeModel, ShiftStatus } from "./postings.types";
import ShiftDetailModal from "./modals/shift-detail-modal.vue";
import OpenShiftRequestModel from "./modals/shift-request-modal.vue";
import FilterPopover from "@/common/table/filter-popover.vue";
import { format as formatPhone } from '@/utils/phoneUtils';
import { format as formatDate } from '@/utils/dateUtils';
import TableMixin from '@/common/table/table.mixin';
import ColumnHeader from '@/common/table/column-header.vue';
import ActionButton from '@/common/table/action-button.vue';
import PostingsMixin from "./postings.mixin";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import EmployeeDetails from "./modals/employee-details.vue";
import ValidatedFormMixin from "@/common/form/validated-form.mixin";

class TableFilters {
  location: string[] = [];
  shiftStatus: ShiftStatus[] = [];
  positionAbbreviation: string[] = [];
  name: string[] = [];
  email: string[] = [];
}

@Component({
  components: {
    ShiftDetailModal,
    OpenShiftRequestModel,
    FilterPopover,
    ColumnHeader,
    ActionButton,
    EmployeeDetails
  },
  computed: {
    ...mapState("Postings", 
    [
      "openJobs", 
      "requestedJobs",
      "confirmedJobs",
      "removedJobs",
      "expiredJobs",
      "selectedOffice",
      "practiceOffices"
    ])
  }
})
export default class PostingTable extends Mixins(TableMixin, PostingsMixin, AuthenticateMixin, ValidatedFormMixin) {
  @Prop() currentUserFilter!: boolean;
  
  openJobs!: IOpenJobPost[];
  requestedJobs!: IRequestedJobPost[];
  confirmedJobs!: AcceptedJobPost[];
  removedJobs!: JobPost[];
  expiredJobs!: JobPost[];
  selectedOffice!: PracticeOfficeModel;
  practiceOffices!: PracticeOfficeModel[];
  showDeleteModal = false;
  selectedJobPost: JobPost | null = null;
  ShiftStatus = ShiftStatus;
  filters = new TableFilters();
  isEmployeeDetailsVisible = false;
  employeeDetails: EmployeeDetailsModel = new EmployeeDetailsModel;
  JobPostStatus = JobPostStatus;
  tableColumns = [
    { key: 'information', label: '', thStyle: { width: '50px' } },
    { key: 'location', label: 'Office', sortable: true, filterId: 'office-filter' },
    { key: 'shiftStatus', sortable: true, filterId: 'shift-status-filter' },
    { key: 'date', sortable: true },
    { key: 'actions', thStyle: { width: '160px' }, sortable: false },
    { 
      key: 'positionAbbreviation',
      label: 'Position',
      sortable: true,
      filterId: 'position-abbreviation-filter',
      filterText: 'position'
    },
    { key: 'name', label: 'Dental Temp', sortable: true, filterId: 'name-filter'},
    { key: 'email', sortable: true, filterId: 'email-filter' },
    { key: 'phone', label: 'Phone Number' }
  ];
  formatPhone = formatPhone;
  formatDate = formatDate;

  get tableData(): JobPost[] {
    const shifts = [
      ...this.openJobs,
      ...this.requestedJobs,
      ...this.confirmedJobs,
      ...this.removedJobs,
      ...this.expiredJobs
    ];

    if (!this.currentUserFilter) {
      return shifts;
    }

    return shifts.filter(jobPost => jobPost.postedByUserId === this.userInfo()?.sub);
  }

  async created(): Promise<void> {
    this.emitLoading(true);
    
    await this.fetchJobPostings();
    this.timerRef = setInterval(() => 
      this.fetchJobPostings(), 60000);

    this.emitLoading(false);
  }

  destroyed(): void {
    clearInterval(this.timerRef);
  }

  async onDateApply(): Promise<void> {
    this.emitLoading(true);

    this.$store.commit('Postings/updateField', { path: 'shiftFilters', value: this.currentShiftFilters });
    this.fetchJobPostings();

    this.emitLoading(false);
  }

  showEmployeeDetailsModal(job: AcceptedJobPost): void {
    this.employeeDetails = {
      name: job.name,
      rating: job.rating,
      phone: job.phone,
      email: job.email,
      date: new Date(job.date),
      position: job.position,
      rate: job.rate,
      timeIn: this.getTimes(job.time, 0, " - "),
      timeOut: this.getTimes(job.time, 1, " - "),
      initials: this.getInitials(job.name),
      isFavorite: job.isFavorite,
      id: job.userId
    };
    this.isEmployeeDetailsVisible = true;
  }

  showShiftRequestModal(jobPost: IRequestedJobPost): void {
    this.selectedJobPost = jobPost;
    this.$bvModal.show('shift-request-modal');
  }

  showInfoModal(jobPost: JobPost): void {
    this.selectedJobPost = jobPost;
    this.showDeleteModal = false;
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: this.practiceOffices.find(office => office.practiceOfficeId === jobPost.practiceOfficeId)
    });
    this.$bvModal.show('shift-detail-modal');
  }

  async deleteShift(jobPost: JobPost): Promise<void> {
    this.selectedJobPost = jobPost;
    this.showDeleteModal = true;
    this.$bvModal.show('shift-detail-modal');
  }

  editShift(id: number): void {
    this.$emit("edit", id);
  }

  getShiftStatusIcon(shiftStatus: ShiftStatus): string {
    switch (shiftStatus) {
      case ShiftStatus.Open:
        return "posted fa-calendar-check";
      case ShiftStatus.JobPostExpired:
      case ShiftStatus.Removed:
        return "fa-calendar-times";
      default:
        return "fa-calendar-check";
    }
  }

  getShiftStatusText(shiftStatus: ShiftStatus): string {
    return ShiftStatus[shiftStatus].replace(/([A-Z])/g, ' $1').trim()
  }

  resetFilters(): void {
    this.filters = new TableFilters();
  }

  async onDelete(): Promise<void> {
    this.emitLoading(true);

    await this.fetchJobPostings();

    this.emitLoading(false);
  }
}
