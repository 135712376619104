import httpAuth from "@/common/http/http-common";
import { 
  LoginDto, 
  NewPasswordDto, 
  RegisterDto 
} from "./authenticate.types";

class AuthenticateService {
  async login(loginDto: LoginDto): Promise<string> {
    const { data } = await httpAuth().post(
      `auth/login`,
      loginDto
    );
    return data;
  }

  async storeUserInfo(): Promise<void> {
    const { data } = await httpAuth().get(`auth/user-info`);
    
    sessionStorage.setItem('user-info', JSON.stringify(data));
  }

  async register(registerDto: RegisterDto) {
    const { data } = await httpAuth().post(
      `auth/register`,
      registerDto
    );
    return data;
  }

  async resetPassword(newPasswordDto: NewPasswordDto) {
    const { data } = await httpAuth().post(
      `auth/reset/confirm`,
      newPasswordDto
    );
    return data;
  }

  async requestPasswordReset(email: string) {
    const { data } = await httpAuth().post(
      `auth/reset`,
      { email: email }
    );
    return data;
  }

  async showWizard(userId: number) {
    const { data } = await httpAuth().get(`wizard/user/${userId}/view`);
    
    return data;
  }

  async getPracticeOffices(userId: number) {
    const { data } = await httpAuth().get(`shift/user/${userId}/practice-office/all`);

    return data;
  }

  async resendConfirmation(model: LoginDto): Promise<void> {
    await httpAuth().post(`auth/confirm/resend`, model);
  }
}

export default new AuthenticateService();