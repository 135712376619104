const format = function(date: string, options?: Intl.DateTimeFormatOptions): string {
  let dateFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
  } 

  if (options) {
    dateFormatOptions = options;
  }

  return new Date(date).toLocaleDateString(undefined, dateFormatOptions)
};

export { format };