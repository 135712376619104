
import { Component, Mixins, Ref } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import { BASE_ROUTES } from "@/views/base/routes";

//import notificationService from "@/views/notifications/notifications.service"
import { RouteConfig } from "vue-router";
//import { IPracticeOfficeNotificationState } from "../notifications/notifications.types";

const basePath = require('@/assets/notification-icons.svg');

@Component
export default class Navigation extends Mixins(AuthenticateMixin) {
  //@Ref() audioElement!: HTMLAudioElement;
  routes: (RouteConfig | undefined)[] = BASE_ROUTES.flatMap((route: RouteConfig) => route.children);
  //notificationState: IPracticeOfficeNotificationState = { lastId: 0, notificationCount: 0};

  getCustomIconRoute(iconName: string): string {
    return basePath + "#" + iconName;
  }

  isRouteVisible(route: RouteConfig): boolean {
    return route.meta?.roles?.some((role: string) => role === this.userInfo()?.role);
  }

  setSelected(): void {
    // if (this.$route.path === this.routes.find(route => route?.name === "Notifications")?.path) {
    //   this.notificationState = {
    //     notificationCount: 0,
    //     lastId: this.lastSeenId
    //   };
    // }
  }

  getSelectedStyle(name: string): string {
    return this.$route.name == name ? 'color: #315564' : 'color: white';
  }

  //timerRef = 0;
  // lastId = 0;
  // lastSeenId = 0;

  get notificationRoute(): RouteConfig | undefined {
    return this.routes.find(route => route?.name === "Notifications");
  }

  // async fetchData(): Promise<void> {    
  //   const notificationState = await notificationService.getOfficeNotificationState(
  //     this.userInfo()?.sub ?? 0,
  //     this.notificationState.lastId
  //   );

  //   if (this.notificationState.lastId === 0 || this.$route.path === this.notificationRoute?.path) {
  //     this.notificationState.lastId = notificationState.lastId;
  //   } else {
  //     if (notificationState.notificationCount > this.notificationState.notificationCount) {
  //       this.audioElement.play();
  //     }

  //     this.notificationState.notificationCount = notificationState.notificationCount;
  //   }

  //   this.lastSeenId = notificationState.lastId;
  // }

  // async mounted(): Promise<void> {
  //   await this.fetchData();
  //   this.timerRef = setInterval(this.fetchData, 15000);
  // }


  // destroyed(): void {
  //   clearInterval(this.timerRef);
  // }
}
