import { UserFormDto } from "@/common/types";
import httpAuth from "../common/http/http-common";

class UserService {
  requestHttp = httpAuth({ requiresAuth: false });

  async getForm(id: number): Promise<UserFormDto> {
    const { data } = await httpAuth().get(`user/${id}/form`);

    return data;
  }

  async update(form: UserFormDto): Promise<void> {
    await httpAuth().put(`user`, form);
  }
}

export default new UserService();