
import { EmployeeDetails, EmployeeKey } from '@/components/employee-details';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import AuthenticateMixin from '../../authenticate/authenticate.mixin';
import { IRequestedJobPost, JobPostGroupResponseStatus, JobPostStatus, PracticeOfficeModel, RequestedWorker } from '../postings.types';

@Component({
  components: {
    EmployeeDetails
  },
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice",
    ])
  }
})
export default class ShiftDetailModal extends Mixins(AuthenticateMixin) {
  @Prop() jobPost?: IRequestedJobPost;
  selectedOffice!: PracticeOfficeModel;
  selectedEmployee: RequestedWorker | null = null;
  isEmployeeDetailsVisible = false;

  get employeeKey(): EmployeeKey {
    return { userId: this.selectedEmployee?.userId ?? 0, practiceId: this.userInfo()?.practiceId ?? 0  }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });
    const weekDay = date.toLocaleString("default", { weekday: "long" });
    const day = date.getDay();

    return `${weekDay}, ${month} ${day}${this.nthNumber(day)}, ${year}`;
  }

  nthNumber(number: number): string {
    if (number > 3 && number < 21) {
      return "th";
    }

    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }


  showEmployeeDetailsModal(employee: RequestedWorker): void {
    this.selectedEmployee = employee;
    this.isEmployeeDetailsVisible = true;
  }
  
  async fetchJobPosts(value: { isFavorite: boolean, rating: number }): Promise<void> {
    const contextEmployee = this.jobPost?.requestedWorkers
        .find(worker => worker.userId === this.selectedEmployee?.userId);

    if (value && contextEmployee) {
      contextEmployee.isFavorite = value.isFavorite;
    }

    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);
  }

  closeModal(): void {
    this.$bvModal.hide('shift-request-modal');
  }

  showDeleteModal(): void {
    this.$bvModal.show("shift-delete-modal");
  }

  async deleteShift(): Promise<void> {
    if (!this.jobPost){
      return;
    }

    await this.$store.dispatch("Postings/setJobPostGroupStatus", {
      jobPostGroupId: this.jobPost.id,
      status: JobPostStatus.Deleted
    });
    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);
    
    this.closeModal();
  }

  async accept(userId: number, jobPostGroupResponseId: number): Promise<void> {
    this.closeModal();

    this.$emit("loading", true);

    await this.$store.dispatch("Postings/setJobPostGroupResponseStatus", {
      jobPostGroupResponseId,
      userId,
      status: JobPostGroupResponseStatus.Confirmed
    });

    this.$emit("refresh");
    this.$emit("loading", false);
  }
}
