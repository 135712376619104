import { Component, Vue } from 'vue-property-decorator';
import { ShiftFilterDto } from './postings.types';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState("Postings", ["shiftFilters"])
  }
})
export default class PostingsMixin extends Vue {
  timerRef = 0;
  shiftFilters!: ShiftFilterDto;
  currentShiftFilters = new ShiftFilterDto();

  getInitials (name: string): string {
    const names = name.split(' ')
    return `${names[0].charAt(0)}${names[1] ? names[1].charAt(0) : ''}`
  }

  getTimes(time: string, field: number, char: string): string {
    const times = time.split(char);
    if (times.length < field) {
      return '';
    }
    return times[field];
  }

  async fetchJobPostings(practiceOfficeId?: number): Promise<void> {
    this.shiftFilters.practiceOfficeId = practiceOfficeId;

    this.$store.dispatch('Postings/resetJobPosts');
    await this.$store.dispatch('Postings/fetchJobPostings', this.shiftFilters);
  }

  emitLoading(value: boolean): void {
    this.$emit('loading', value);
  }
}