
import { Component, Prop, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { mapState } from "vuex";
import { 
  ShiftFormDto,
  PracticeOfficeModel,
  Positions,
  ShiftFormDataDto,
  PayRateStatistics
} from "../postings.types";
import AuthenticateMixin from "../../authenticate/authenticate.mixin";
import moment from "moment";

@Component({
  computed: {
    ...mapState("Postings", {
      selectedOffice: "selectedOffice",
      practiceOffices: "practiceOffices",
      formData: "shiftFormData",
      form: "shiftForm",
      statistics: "payRateStatistics"
    }),
  },
})
export default class PostShift extends Mixins(AuthenticateMixin) {
  @Prop() modalShow!: boolean;
  @Prop() isGridView!: boolean;

  form!: ShiftFormDto;
  formData!: ShiftFormDataDto;
  statistics!: PayRateStatistics;
  selectedOffice!: PracticeOfficeModel;
  practiceOffices!: PracticeOfficeModel[];
  veeFields!: Field;
  veeErrors!: ErrorBag;
  
  isBusy = false;

  get postToOptions(): { text: string, value: number, disabled: boolean }[] {
    var isDisabled = this.formData.favoriteCount === 0;
    return [
      { text: 'All', value: 0, disabled: isDisabled }, 
      { text: 'Favorites Only', value: 1, disabled: isDisabled },
    ];
  }

  get otherFormGroupDisabled(): boolean {
    return this.form.positionId != Positions.DentalHygienist;
  }

  get postTo(): number[] {
    return [this.form.postTo];
  }

  set postTo(value: number[]) {
    if (value.length === 0) {
      this.form.postTo = this.form.postTo === 0 ? 1 : 0;
      return;
    }

    var newValue = value[value.length - 1];
    if (newValue != this.form.postTo)
      this.form.postTo = newValue;
  }

  get minDate(): Date {
    return new Date();
  }

  get payRate(): string | undefined {
    return this.form.payRate?.toFixed(2);
  }

  async onOfficeSelect(value: PracticeOfficeModel): Promise<void> {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
    this.isBusy = true;
    await this.fetchData();
    this.isBusy = false;
  }

  setPayRate(value: string | undefined): void {
    if (value) 
      this.form.payRate = parseFloat(value);
  }

  async mounted(): Promise<void> {
    this.isBusy = true;
    await this.fetchData();
    if (!this.form.shiftGroupId) {
      this.$store.commit("Postings/updateField", {
        path: "payRateStatistics",
        value: new PayRateStatistics()
      })
    }
    this.isBusy = false;
  }

  onChange(date: Date): void {
    const dow = moment(date).isoWeekday() % 7 + 1;
    const officeHours = this.formData.hours.find(obj => {
      return obj.dayOfWeek === dow
    });
    this.form.timeIn = officeHours?.openTime == null ? "08:00:00" : officeHours.openTime;
    this.form.timeOut = officeHours?.closeTime == null ? "17:00:00" : officeHours.closeTime;
    this.form.breakTime = officeHours?.breakMinutes == null ? 0 : officeHours.breakMinutes;
  }

  validateState(ref: string): boolean | null {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async fetchData() : Promise<void> {
    await this.$store.dispatch("Postings/getShiftFormData", this.selectedOffice.practiceOfficeId);
  }

  async createJobPost(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.form.practiceOfficeId = this.selectedOffice.practiceOfficeId;

      if (this.form.channel){
        this.form.channel = parseInt(this.form.channel.toString());
      }

        await this.$store.dispatch("Postings/saveShift", this.form);

        this.$emit('reload');
        this.$emit('update:modalShow', false);
      }
    });
  }

  async onPositionSelect(value: number): Promise<void> {
    const officeRates = this.formData.rates.find(rate => rate.positionId === value);

    this.form.payRate = officeRates?.amount ?? null;

    if (value != Positions.DentalHygienist) {
      this.form.channel = undefined;
    }

    this.isBusy = true;
    await this.$store.dispatch("Postings/getPayRateStatistics", value);
    this.isBusy = false;
  }

  closeModal(): void {
    this.$emit('update:modalShow', false);
  }
}
