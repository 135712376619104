import httpAuth from "@/common/http/http-common";
import {
  JobPostGroupStatusDto,
  JobPostGroupSetDto,
  IPracticeOfficeJobPost,
  PracticeOfficeRate,
  PracticeOfficeModel,
  PracticeOfficeHour,
  ShiftFormDataDto,
  ShiftFilterDto,
  PayRateStatistics,
  ShiftFormDto
} from "./postings.types";

class PostingsService {
  async getPracticeOffices(userId: number): Promise<PracticeOfficeModel[]> {
    const { data } = await httpAuth().get(`shift/user/${userId}/practice-office/all`);

    return data;
  }

  async saveShift(model: ShiftFormDto): Promise<void> {
    const { data } = await httpAuth().put(`shift`, model);

    return data;
  }

  async getShiftForm(shiftGroupId: number): Promise<ShiftFormDto> {
    const { data } = await httpAuth().get(`shift/${shiftGroupId}/form`);

    return data;
  }

  async getShiftFormData(practiceOfficeId: number): Promise<ShiftFormDataDto> {
    const { data } = await httpAuth().get(`shift/practice-office/${practiceOfficeId}/form/data`);

    return data;
  }

  async getPayRateStatistics(positionId: number): Promise<PayRateStatistics> {
    const { data } = await httpAuth().get(`shift/position/${positionId}/pay-stats`);

    return data;
  }

  async getPracticeOfficeJobPostings(model: ShiftFilterDto): Promise<IPracticeOfficeJobPost[]> {
    if (!model.startDate) {
      model.startDate = null;
    }

    if (!model.endDate) {
      model.endDate = null;
    }

    const { data } = await httpAuth().post('shift/get-all/practice-office', model);

    return data;
  }

  async setJobPostGroupStatus(model: JobPostGroupStatusDto): Promise<void> {
    const { data } = await httpAuth().put(`shift/group/${model.jobPostGroupId}/status`, model.status);

    return data;
  }

  async setJobPostGroupResponseStatus(model: JobPostGroupSetDto): Promise<void> {
    const { data } = await httpAuth().put(`shift/group/response/status`, model);

    return data;
  }

  async getPracticeOfficeHours(practiceOfficeId: number): Promise<PracticeOfficeHour[]> {
    const { data } = await httpAuth().get(
      `office-profile/practice-office/${practiceOfficeId}/hour/all`
    );
    
    return data;
  }

  async getPracticeOfficeDefaultRates(practiceOfficeId: number): Promise<PracticeOfficeRate[]> {
    const { data } = await httpAuth().get(
      `office-profile/practice-office/${practiceOfficeId}/rate/all`
    );

    return data;
  }

  async addToFavorites(practiceOfficeId: number, userId: number): Promise<void> {
    const { data } = await httpAuth().put(
      `office-profile/practice-office/${practiceOfficeId}/user/${userId}/favorite`,
    );

    return data;
  }

  async removeFromFavorites(practiceOfficeId: number, userId: number): Promise<void> {
    const { data } = await httpAuth().delete(
      `office-profile/practice-office/${practiceOfficeId}/user/${userId}/favorite`,
    );
    
    return data;
  }
}

export default new PostingsService();