import httpAuth from "@/common/http/http-common";
import { VacancyDetailsDto, IVacancyDto, VacancyFormDataDto, VacancyFormDto, VacancyApplicantNoteFormDto } from "./vacancies-types";

class VacancyService {
  async get(practiceId: number): Promise<IVacancyDto[]> {
    const { data } = await httpAuth().get(`vacancy/practice/${practiceId}`);

    return data;
  }

  async getForm(id: number): Promise<VacancyFormDto> {
    const { data } = await httpAuth().get(`vacancy/${id}/form`);

    return data;
  }

  async getFormData(practiceId: number): Promise<VacancyFormDataDto> {
    const { data } = await httpAuth().get(`vacancy/practice/${practiceId}/form/data`);

    return data;
  }

  async getDetails(id: number, practiceId: number): Promise<VacancyDetailsDto> {
    const { data } = await httpAuth().get(`Vacancy/${id}/practice/${practiceId}/details`);

    return data;
  }

  async delete(id: number): Promise<void> {
    await httpAuth().delete(`vacancy/${id}`);
  }

  async toggleInterest(userId: number, vacancyId: number, isInterested: boolean | null): Promise<void> {
    await httpAuth().put(`vacancy/${vacancyId}/user/${userId}/interest`, isInterested);
  }

  async toggleHire(userId: number, vacancyId: number): Promise<void> {
    await httpAuth().put(`vacancy/${vacancyId}/user/${userId}/hire`);
  }

  async toggleFill(vacancyId: number): Promise<void> {
    await httpAuth().put(`vacancy/${vacancyId}/fill`);
  }

  async save(model: VacancyFormDto): Promise<void> {
    await httpAuth().put("vacancy", model);
  }

  async saveNote(model: VacancyApplicantNoteFormDto, userId: number): Promise<void> {
    await httpAuth().put(`vacancy/user/${userId}/note`, model);
  }

  async deleteNote(id: number): Promise<void> {
    await httpAuth().delete(`vacancy/note/${id}`);
  }
}

export default new VacancyService();