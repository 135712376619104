import httpAuth from "@/common/http/http-common";
import { UpsertSchedulerDto } from "./schedulers.types";

class SchedulersService {
  async getPracticeSchedulers(practiceId: number) {
    const { data } = await httpAuth().get(`scheduler/practice/${practiceId}/all`);

    return data;
  }

  async getPracticeOffices(practiceId: number) {
    const { data } = await httpAuth().get(`scheduler/practice/${practiceId}/practice-office/all`);

    return data;
  }

  async upsertScheduler(model: UpsertSchedulerDto) {
    const { data } = await httpAuth().post(`scheduler`, model);
    
    return data;
  }
}

export default new SchedulersService();