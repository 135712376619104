import { ErrorBag, Field } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ValidatedFormMixin extends Vue {
  veeFields!: Field;
  veeErrors!: ErrorBag;

  validateState(ref: keyof Field): boolean | null {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }
}